import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../API/Api";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ mode: "onChange" });

  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem('ContactaddressData');
        const response = await axios.get(`${ApiUrl}/get/contactDetails`);
        if (cachedData !== response) {
          setAddress(response?.data?.data);
          localStorage.setItem('ContactaddressData', JSON.stringify(response?.data?.data));
        } else {
          setAddress(JSON.parse(cachedData));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const onSubmitForm = async (formData) => {
    try {
      await axios.post(`${ApiUrl}/store/contactform`, formData);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Your message has been sent successfully!',
      });
      reset();
    } catch (error) {
      console.error('Error submitting form:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again later.',
      });
    }
  };

  return (
    <>
      <div className="header-height" />
      <section className="contact-section padding">
        <div className="container">
          <div className="section-heading mb-40">
            <div className="sec-heading">
              <h2 className="text-center">
                <strong style={{ color: "#16846e" }}>Contact</strong>{" "} <strong style={{ color: "#f5ab21" }}>Us</strong>
              </h2>
              <div className="row contact-wrap">
                <div className="col-md-6 xs-padding">
                  <div className="contact-info">
                    {address ? (
                      <iframe
                        src={address?.googleMapsUrl}
                        width="100%"
                        title="pwckjp"
                        height="200"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"></iframe>
                    ) : null}
                    <ul>
                      <br />
                      <li>
                        <i className="ti-location-pin" /> {address?.address}
                      </li>
                      <li>
                        <i className="ti-mobile" />
                        <a href={`tel:${address?.mobile}`} className="emil">
                          {address?.mobile}
                        </a>
                      </li>
                      <li>
                        <i className="ti-email" />
                        <a
                          href={`mailto:${address?.email}`}
                          className="emil">
                          {address?.email}
                        </a>
                      </li>
                      <li>
                        <i className="ti-link" />
                        <a href="https://sultanpetmsss.org/">https://sultanpetmsss.org</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 xs-padding">
                  <div className="contact-form">
                    <h3>Contact Us By Message</h3>
                    <p>
                      Your email address will not be published. Required fields are
                      marked *
                    </p>
                    <form
                      onSubmit={handleSubmit(onSubmitForm)}
                      className="form-horizontal">
                      <div className="form-group colum-row row">
                        <div className="col-sm-6">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            {...register("name", { required: true })}
                            aria-invalid={errors?.name ? "true" : "false"}
                            className="form-control"
                            placeholder="Name"
                          />
                          {errors?.name?.type === "required" && (
                            <div className="text-danger text_error">
                              <label className="errlabel">Name is required</label>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            {...register("email", { required: true })}
                            aria-invalid={errors?.email ? "true" : "false"}
                            className="form-control"
                            placeholder="Email"
                            required=""
                          />
                          {errors?.email?.type === "required" && (
                            <div className="text-danger text_error">
                              <label className="errlabel">Email is required</label>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-12">
                          <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            {...register("mobile", { required: true })}
                            aria-invalid={errors?.mobile ? "true" : "false"}
                            className="form-control"
                            placeholder="Mobile"
                            required=""
                          />
                          {errors?.mobile?.type === "required" && (
                            <div className="text-danger text_error">
                              <label className="errlabel">Mobile Number is required</label>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-12">
                          <textarea
                            id="message"
                            name="message"
                            cols={30}
                            rows={5}
                            {...register("message", { required: true })}
                            aria-invalid={errors?.message ? "true" : "false"}
                            className="form-control message"
                            placeholder="Message"
                          />
                          {errors?.message?.type === "required" && (
                            <div className="text-danger text_error">
                              <label className="errlabel">
                                Message is required
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-12">
                          <button id="submit" className="default-btn" type="submit">
                            Send Message
                          </button>
                        </div>
                      </div>
                      <div id="form-messages" className="alert" role="alert" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
