import React, { useEffect, useState } from "react";
import { ApiUrl } from "../API/Api";
import axios from "axios";

const Footer = () => {
  const [address, setAddress] = useState(null);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem('addressData');
        const response = await axios.get(`${ApiUrl}/get/contactDetails`);
        if (cachedData !== response) {
          setAddress(response?.data?.data);
          localStorage.setItem('addressData', JSON.stringify(response?.data?.data));
        } else {
          setAddress(JSON.parse(cachedData));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <>
        <section className="widget-section padding">
          <div className="container">
            <div className="widget-wrap row">
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Contact Us</h3>
                  {address ? (
                    <ul className="widget-link">
                      <li>
                        <p>
                          <i className="fa fa-map-marker"></i> {address?.address}
                        </p>
                      </li>
                      <li>
                        <p>
                          <a href="https://sultanpetmsss.org/" target="_blank" rel="noreferrer">
                            <i className="fa fa-globe"></i>  https://sultanpetmsss.org/
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a href={`tel:${address?.mobile}`} target="_blank" rel="noreferrer">
                            <i className="fa fa-phone"></i> {address?.mobile}
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a href={`mailto:${address?.email}`} target="_blank" rel="noreferrer">
                            <i className="fa fa-envelope"></i> {address?.email}
                          </a>
                        </p>
                      </li>
                    </ul>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Recent Campaigns</h3>

                  <ul className="social-icon">
                    <li>
                      <a
                        href="/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/fb.png" alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/twitter-icon.jpg" alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/youtube.jpg" alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/insta.jpg" alt="twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Location</h3>
                  <iframe
                    loading="lazy"
                    style={{ border: "0", height: "180px" }}
                    tabIndex="0"
                    src={address?.googleMapsUrl}
                    frameBorder="0"
                    title="ourLocation"
                    allowFullScreen=""
                    aria-hidden="false"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 sm-padding">
                <div className="copyright">
                  Copyright © {new Date().getFullYear()} - All Rights Reserved
                  By -{" "}
                  <span style={{ color: "#ac7865" }}>
                    Sultanpet Multipurpose Social Service Society
                  </span>
                </div>
              </div>
              <div className="col-md-4 sm-padding">
                <div className="copyright">
                  <span>Powered by</span>
                  <a
                    href="https://www.boscosofttech.com/"
                    rel="noreferrer"
                    className="ml-2"
                    style={{ color: "#ac7865" }}
                    target="_blank">
                    Bosco Soft Technologies Pvt. Ltd.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a
          href="#header"
          id="scroll-to-top"
          onClick={() => window.scrollTo(0, 0)}>
          <i className="arrow_up" />
        </a>
      </>
    </>
  );
};

export default Footer;
