import React from "react";
import { Link } from "react-router-dom";

const AboutUsHome = () => {
  return (
    <>
      <div id="abouthome"></div>
      <div className="team-section bg-grey bd-bottom circle shape padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-9 col-md-12 col-sm-12 wow fadeInLeft delay-04s align-self-center"
              style={{ visibility: "visible", animationName: "fadeInLeft" }}>
              <div className="text">
                <h1>Welcome to Sultanpet Multipurpose Social Service Society</h1>
                <p className="mb-4" id="aboutdesc">
                  Our Holy Farther Francis erected Sultanpet, in Palakkad district, Kerala, as a new Latin Catholic Diocese in December 2013. Why the name: Sultanpet? The Syro-Malabr diocese was created some 50 years ago in this district of Palakkad as: The Diocese of Palakkad. Therefore in order to avoid confusion this new Latin rite diocese was named as: the diocese of Sultanpet, where the new cathedral is situated in the town of Palakkad. This new diocese was bifurcated mostly from the parishes of Coimbatore (Tamilnadu).
                </p>
                <p className="mb-30" id="aboutdesc">
                Initially there are 16 parishes of Tamil speaking people from the diocese of Coimbatore and 5 parishes of Malayalam speaking people from the diocese of Calicut. This new diocese, as you see in the map, is a border area touching both Kerala and Tamilnadu (two political States of India). This is the 12th Latin Catholic Diocese in the ecclesiastical region of Kerala and 31st Catholic diocese of Kerala (including three rites) and 167th Catholic diocese of India. By the grace of God I was consecrated and installed on 16th February 2014 as the first bishop of this new diocese: Sultanpet.
                </p>
                <Link to="/aboutus" className="default-btn">
                  Read More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 col-sm-12 wow fadeInRight delay-04s"
              style={{ visibility: "visible", animationName: "fadeInRight" }}>
              <div className="hotel-banner">
                <div className="pull-right">
                  <div className="donate-us-box">
                    <h5>GIVE YOUR DONATIONS</h5>
                    <span>Donation needed</span>{" "}
                    <span className="amount-figures">
                      <strong>₹</strong>
                      25,000
                    </span>{" "}
                    <span>Collection Donation</span>
                    <span className="amount-figures coloured" id="amountnews">
                      <strong>₹</strong>
                      0
                    </span>{" "}
                    <span className="cell">
                      <i className="fa fa-phone" />
                      9562232075
                    </span>
                    <center>
                      <button
                        data-toggle="modal"
                        data-target="#myModal"
                        data-url="https://pwckjp.org/"
                        data-type="general"
                        className=" default-btn"
                        data-security="16e439bce5">
                        Donate Now
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHome;
