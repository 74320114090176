import React from 'react';
import './Project.css'

const OurProjects = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center mb-40 mt-3">
                        <div className="sec-heading">
                            <h2><strong style={{ color: "#16846e" }}>Our Welfare</strong> <strong style={{ color: "#f5ab21" }}>Projects</strong></h2>
                            <span className="heading-border" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-envelope" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                <h4>LOK MANCH</h4>
                                <p>
                                    The main of this project is to promote the dignity and well-being of marginalized people in Attappady Kerala through policy interventions and improved access to their legal rights. .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-users" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                <h4>Kolping India</h4>
                                <p>
                                    Kolping Day function in Group Dused pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor ucnibh. Nullam
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-search" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                <h4>Caritas India: Disaster Risk Reduction (DRR)</h4>
                                <p>
                                    The main motive of this project is “say yes to life and say no to cancer”. This project aims to create awareness and promote among the people about organic farming.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProjects;
