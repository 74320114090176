import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ApiUrl } from "../API/Api";
import axios from "axios";
const Header = ({ menudata }) => {
  const [showMenu, setShowMenu] = useState(false);
  let location = useLocation();
  const url = location.pathname;
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [address, setAddress] = useState(null);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem('addressData');
        const response = await axios.get(`${ApiUrl}/get/contactDetails`);
        if (cachedData !== response) {
          setAddress(response?.data?.data);
          localStorage.setItem('addressData', JSON.stringify(response?.data?.data));
        } else {
          setAddress(JSON.parse(cachedData));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <header id="header" className="header-section">
        <div className="top-header">
          <div className="container">
            <div className="top-content-wrap row">
              <div className="col-sm-8">
                {address && (
                  <ul className="left-info">
                    <li>
                      <i className="fa fa-envelope" />
                    </li>
                    <li>
                      <a href="mailto:{address.email}">{address.email}</a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                    </li>
                    <li>
                      <a href="tel:{address.mobile}">{address.mobile}</a>
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-sm-4 d-none d-md-block">
                <ul className="right-info">
                  <li>
                    <a href="/">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-header">
          <div className="container">
            <div className="bottom-content-wrap row">
              <div className="col-sm-3">
                <div className="site-branding">
                  <Link to={"/"}>
                    <img src="images/logo.png" alt="Brand" id="logo-img-header" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-9 text-right">
                <ul id="mainmenu" className="nav navbar-nav nav-menu">
                  {menudata?.map((item) => (
                    <li key={item.id} className={item.children ? "dropdown" : ""}>
                      <NavLink
                        to={item.url}
                        className={`${item.url === url ||
                          (item.children &&
                            item.children.some((child) => child.url === url))
                          ? "active"
                          : ""}`}
                      >
                        {item.label}
                      </NavLink>

                      {item.children && (
                        <ul>
                          {item.children.map((child) => (
                            <li key={child.id}>
                              <NavLink
                                to={child.url}
                                className={child.url === url ? "active" : ""}
                              >
                                {child.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Hamburger Icon for Menu Toggle */}
      <div className="navbar-toggler d-md-none" onClick={toggleMenu}>
        <div className="menu-icon"></div>
        <div className="menu-icon"></div>
        <div className="menu-icon"></div>
      </div>
      {/* Offcanvas menu */}
      <div
        className={`offcanvas offcanvas-start d-md-none ${showMenu ? "show" : ""
          }`}
        tabIndex="-1"
        id="offcanvas">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Menu</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={toggleMenu}></button>
        </div>
        <div className="offcanvas-body">
          <ul>
            <li className="dropdown">
              <Link to={"/"}>
                <span>Home</span>
              </Link>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                About Us
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="our-vision">Our Vision</a>
                </li>
                <li>
                  <a href="mission">Our Mission</a>
                </li>
                <li>
                  <a href="objectives">Our Objectives</a>
                </li>
                <li>
                  <a href="history">History</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                On Going Projects
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="projects-completed">Projects Completed</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                Our Team
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="social-work-commission">Social Work Commission</a>
                </li>
                <li>
                  <a href="executive-committee">Executive Committee</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                Gallery
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="youtube-video-gallery">YouTube Video Gallery</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="contact-us">Contact Us</a>
            </li>
            <li className="dropdown">
              <button
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="btn btn-primary getstarted">
                Donate Now
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
