import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const UpcomeEvents = ({ newsdata }) => {
  const filteredData = newsdata.filter(cause => cause.category_id === 4);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const cardStyle = {
    height: '150px',
  };

  return (
    <section className="team-section bg-grey bd-bottom circle shape padding">
      <div className="container">
        <div className="section-heading text-center mb-40">
          <div className="sec-heading">
            <h2><strong style={{ color: "#16846e" }}>Our Recent</strong> <strong style={{ color: "#f5ab21" }}>News</strong></h2>
            <span className="heading-border" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 xs-padding">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={300}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px">
              {filteredData?.map((recent, index) => (
                <div key={index} className="padding-15">
                  <div className="blog-post">
                    <img src={recent?.media_url} alt="blog post" />
                    <div className="blog-content" style={cardStyle}>
                      <span className="date">
                        <i className="fa fa-clock-o" /> {recent?.created_date}
                      </span>
                      <h3>
                        <Link to={`recentnews?newsid=${recent?.id}`}>{recent.title}</Link>
                      </h3>
                      <Link to={`recentnews?newsid=${recent?.id}`} className="post-meta">Read More</Link>
                    </div>
                  </div>
                  <br/>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpcomeEvents;
