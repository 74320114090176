import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import MainLayout from "./layout";
import About from "./SubPages/AboutUs/About";
import BishopMessage from "./SubPages/AboutUs/BishopMessage";
import DirectorMessage from "./SubPages/AboutUs/DirectorMessage";
import Activity from "./SubPages/AreaofInterest/Activity";
import Intervention from "./SubPages/Interventions/Intervention";
import Sebastine from "./SubPages/Institutions/Sebastine";
import PhotoGallery from "./SubPages/Gallery/gallery";
import Contact from "./SubPages/ContactUs/Contact";
import RecentNews from "./SubPages/Recent News/RecentNews";
import RecentCause from "./SubPages/Recent News/RecentCause";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<MainLayout />}>
            {/* About Us Pages Routing */}
            <Route path="about-us" element={<About />} />
            <Route path="history" element={<About />} />
            <Route path="target-area" element={<About />} />
            <Route path="socio-economic" element={<About />} />
            <Route path="organogram" element={<About />} />
            <Route path="general-body" element={<About />} />
            <Route path="governing-body" element={<About />} />
            <Route path="our-team" element={<About />} />
            <Route path="regional-centres" element={<About />} />
            <Route path="geo-area" element={<About />} />
            <Route path="our-organization" element={<About />} />
            <Route path="bishop-message" element={<BishopMessage />} />
            <Route path="director-message" element={<DirectorMessage />} />
            <Route path="objectives" element={<About />} />
            {/* Area of Interest Pages Routing */}
            <Route path="activity" element={<Activity />} />
            <Route path="wshg" element={<Activity />} />
            <Route path="eruthenpathy-federation" element={<Activity />} />
            <Route path="sayathurai-federation" element={<Activity />} />
            <Route path="palaniyar-federation" element={<Activity />} />
            <Route path="walayar-federation" element={<Activity />} />
            <Route path="attappady-federation" element={<Activity />} />
            <Route path="formation-group" element={<Activity />} />
            {/* Interventions Pages Routing */}
            <Route path="kolping" element={<Intervention />} />
            <Route path="kutti-koottam" element={<Intervention />} />
            <Route path="child-parliment" element={<Intervention />} />
            <Route path="child-sponsorship" element={<Intervention />} />
            <Route path="jeevamirdam" element={<Intervention />} />
            <Route path="jeevasurabi" element={<Intervention />} />
            <Route path="afford" element={<Intervention />} />
            <Route path="spinsters" element={<Intervention />} />
            <Route path="need-child" element={<Intervention />} />
            <Route path="relief-assist" element={<Intervention />} />
            {/* Institution Pages Routing */}
            <Route path="sebastin-iti" element={<Sebastine />} />
            {/* Gallery Pages Routing */}
            <Route path="gallery" element={<PhotoGallery />} />
            {/* Contact Us Pages Routing */}
            <Route path="contact-us" element={<Contact />} />
            {/* Latest News Pages Routing */}
            <Route path="recentnews" element={<RecentNews />} />
            <Route path="ourcause" element={<RecentCause />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
