import React from 'react';
import { Carousel } from 'react-bootstrap';

const Slider = ({ sliderdata }) => {
    return (
        <Carousel
            interval={20000}
            prevLabel=''
            nextLabel=''
            ride="carousel"
            wrap={true}
            indicators={true}
            pause={true}
            fade={true}
            controls={true}
            touch={true}
            keyboard={true}
            className="slider-wrapper"
        >
            {sliderdata?.map((slider, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={slider?.image}
                        alt="Slider"
                        id='myslider'
                        style={{ height: '70vh' }}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default Slider;
