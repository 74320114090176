import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MainStyle.css';
import { ApiUrl } from '../API/Api';

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        axios.get(`${ApiUrl}/get/testimonial/1`)
            .then(response => {
                if (response.data.success) {
                    setTestimonials(response.data.data);
                } else {
                    console.error('Failed to fetch testimonials:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching testimonials:', error);
            });
    }, []);

    return (
        <>
            <section className="testimonials" style={{ background: `url('/images/testbg.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="heading text-center">
                    <h2>
                        Our People <span> Says </span>
                    </h2>
                </div>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    {testimonials?.map((testimonial, index) => (
                                        <li
                                            key={testimonial.id}
                                            data-target="#carouselExampleIndicators"
                                            data-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                        />
                                    ))}
                                </ol>
                                <div className="carousel-inner">
                                    {testimonials?.map(testimonial => (
                                        <div
                                            key={testimonial.id}
                                            className={`carousel-item ${testimonial.id === testimonials[0].id ? "active" : ""} text-center`}
                                        >
                                            <img
                                                src={testimonial.image}
                                                alt=""
                                                className="center-block team"
                                            />
                                            <h3>{testimonial.title}</h3>
                                            <h4>{ testimonial.date}</h4>
                                            <div dangerouslySetInnerHTML={{ __html: testimonial.content }} />
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="carousel-control-prev control"
                                    data-target="#carouselExampleIndicators"
                                    data-slide="prev"
                                >
                                    <span className="fa fa-angle-left icon" aria-hidden="true" />
                                    <span className="sr-only">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next control"
                                    data-target="#carouselExampleIndicators"
                                    data-slide="next"
                                >
                                    <span className="fa fa-angle-right icon" aria-hidden="true" />
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Testimonials;
