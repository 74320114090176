import Slider from "./Components/Slider";
import Header from "./Components/Header";
import OurCasues from "./Components/OurCasues";
import Testimonials from "./Components/Testimonials";
import UpcomeEvents from "./Components/UpcomeEvents";
import Footer from "./Components/Footer";
import AboutUsHome from "./Components/AboutUsHome";
import OurProjects from "./Components/OurProjects";
import { ApiUrl } from "./API/Api";
import axios from "axios";
import { useState, useEffect } from "react";
import {RotatingSquare} from 'react-loader-spinner'

const Home = () => {
  const [homedata, setHomedata] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchpageData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        const newData = response?.data?.pages;
        return newData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchpageData();
    fetchData();
  }, []);


  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <RotatingSquare
          visible={true}
          height="100"
          width="100"
          color="#4FC0AA"
          ariaLabel="rotating-square-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  return (
    <>
      <Header menudata={homedata?.headermenudata} />
      <Slider sliderdata={homedata?.SlidesData} />
      <AboutUsHome />
      <OurCasues newsdata={homedata?.newsdata} />
      <Testimonials testmonialdata ={homedata?.testmonialdata} />
      <UpcomeEvents newsdata={homedata?.newsdata} />
      <OurProjects />
      <Footer />
    </>
  );
};

export default Home;
