import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MainStyle.css';
import { Link } from 'react-router-dom';

const OurCauses = ({ newsdata }) => {
    const filteredData = newsdata.filter(cause => cause.category_id === 3);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    return (
        <section className="causes-section bd-bottom padding">
            <div className="container">
                <div className="section-heading text-center mb-40">
                    <div className="sec-heading">
                        <h2><strong style={{ color: "#16846e" }}>Our</strong> <strong style={{ color: "#f5ab21" }}>Causes</strong></h2>
                        <span className="heading-border" />
                    </div>
                </div>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={6000}
                    keyBoardControl={true}
                    customTransition="transform 300ms ease-in-out"
                    transitionDuration={300}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {filteredData?.map((cause, index) => (
                        <div key={index} className="causes-card" style={{ margin: '0 10px',padding: '6px' }}>
                            <div className="causes-content">
                                <div className="causes-thumb">
                                    <img src={cause.media_url} alt="causes" />
                                </div>
                                <div className="causes-details">
                                    <h3>{cause.title}</h3>
                                    <Link to={`ourcause?causeid=${cause?.id}`}>Read More</Link>
                                    {/* recentnews?newsid=${recent?.id} */}
                                </div>
                            </div>
                            <br/>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default OurCauses;
